import { render, staticRenderFns } from "./_.vue?vue&type=template&id=1f4d9691&"
import script from "./_.vue?vue&type=script&lang=js&"
export * from "./_.vue?vue&type=script&lang=js&"
import style0 from "./_.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AppDynamicComponent: require('/furanet/sites/runnea.de/web/htdocs/nuxt/components/AppDynamicComponent.vue').default,AppBreadcrumb: require('/furanet/sites/runnea.de/web/htdocs/nuxt/components/AppBreadcrumb.vue').default,MenuMagazine: require('/furanet/sites/runnea.de/web/htdocs/nuxt/components/MenuMagazine.vue').default,AppSocial: require('/furanet/sites/runnea.de/web/htdocs/nuxt/components/AppSocial.vue').default,AuthorMiniLeaf: require('/furanet/sites/runnea.de/web/htdocs/nuxt/components/AuthorMiniLeaf.vue').default,AppLink: require('/furanet/sites/runnea.de/web/htdocs/nuxt/components/AppLink.vue').default,ArticleCard: require('/furanet/sites/runnea.de/web/htdocs/nuxt/components/ArticleCard.vue').default,AuthLoginForm: require('/furanet/sites/runnea.de/web/htdocs/nuxt/components/AuthLoginForm.vue').default,ModuleComments: require('/furanet/sites/runnea.de/web/htdocs/nuxt/components/ModuleComments.vue').default,NewCommentModule: require('/furanet/sites/runnea.de/web/htdocs/nuxt/components/NewCommentModule.vue').default,AuthorLeaf: require('/furanet/sites/runnea.de/web/htdocs/nuxt/components/AuthorLeaf.vue').default})
