import Vue from 'vue'
import Router from 'vue-router'

// Importar páginas
import ArticleList from '~/pages/article_list/_.vue'
import ArticlePag from '~/pages/article_page/_.vue'
import AudiosList from '~/pages/audios_list/_.vue'
import AudiosPage from '~/pages/audios_page/_.vue'
import AuthLogin from '~/pages/auth_login/_.vue'
import AuthCallback from '~/pages/auth_callback/_.vue'
import AuthRedirect from '~/pages/auth_redirect/_.vue'
import AuthRemember from '~/pages/auth_remember/_.vue'
import AuthData from '~/pages/auth_data/_.vue'
import AuthNewsletter from '~/pages/auth_newsletter/_.vue'
import AuthPassword from '~/pages/auth_password/_.vue'
import AuthSignin from '~/pages/auth_signin/_.vue'
import CountriesRedirect from '~/pages/countries_redirect/_.vue'
import AuthorList from '~/pages/author_list/_.vue'
import BrandList from '~/pages/brand_list/_.vue'
import Calculator from '~/pages/calculator/_.vue'
import CalculatorMarathon from '~/pages/calculator_marathon/_.vue'
import CalculatorHalfMarathon from '~/pages/calculator_half_marathon/_.vue'
import Calculator10Km from '~/pages/calculator_10_km/_.vue'
import ComparatorPag from '~/pages/comparator_page/_.vue'
import ComparisonPag from '~/pages/comparison_page/_.vue'
import DiscountCodesList from '~/pages/discount_codes_list/_.vue'
import GalleryPag from '~/pages/gallery_page/_.vue'
import Recommender from '~/pages/recommender/_.vue'
import LandingPag from '~/pages/landing_page/_.vue'
import InquiryList from '~/pages/inquiry_list/_.vue'
import InquiryPage from '~/pages/inquiry_page/_.vue'
import PriceList from '~/pages/price_list/_.vue'
import ProductList from '~/pages/product_list/_.vue'
import ProductPag from '~/pages/product_page/_.vue'
import ProductPrices from '~/pages/product_prices/_.vue'
import ProductVariant from '~/pages/product_variant/_.vue'
import RedirectingPag from '~/pages/redirecting/_.vue'
import RedirectingUrlPag from '~/pages/redirecting/tracking_url.vue'
import ReviewList from '~/pages/review_list/_.vue'
import OpinionList from '~/pages/opinion_list/_.vue'
import RaceList from '~/pages/race_list/_.vue'
import RacePage from '~/pages/race_page/_.vue'
import StorePage from '~/pages/store_page/_.vue'
import NotFoundPage from '~/pages/not_found_page/_.vue'
import TagPage from '~/pages/tag_page/_.vue'
import TalkPage from '~/pages/talk_page/_.vue'
import UserPage from '~/pages/user_page/_.vue'
import * as scrollFunctions from '~/mixins/scroll.functions.js'



Vue.use(Router)

export function createRouter () {
  return new Router({
    mode: 'history',
    scrollBehavior: (to, from) => {
      scrollFunctions.init()

      if (to.hash) {
        scrollFunctions.set(to.hash)
        return false;
      }

      return { x: 0, y: 0 }
    },
    routes: [
      {
				path:'/nutzer/',
				component:UserPage
			},
			{
				path:'/artikel/autoren/:pag(\\d+)?',
				component:AuthorList,
				props:{"filter":"articles"}
			},
			{
				path:'/artikel/autoren/:autor/:pag(\\d+)?',
				component:ArticleList,
				props:{"breadcrumb":[{"text":"autoren","href":"/artikel/autoren/"}]}
			},
			{
				path:'/artikel/kategorien/:categoria/:pag(\\d+)?',
				component:ArticleList,
				props:{"breadcrumb":[{"text":"Articulos","href":"/artikel/"}]}
			},
			{
				path:'/artikel/thema/:etiqueta/:pag(\\d+)?',
				component:ArticleList,
				props:{"article":"etiqueta","filter":"tag","breadcrumb":[{"text":"etiqueta","href":"/artikel/etiquetas/"}]}
			},
			{
				path:'/artikel/:pag(\\d+)?',
				component:ArticleList,
				props:{"breadcrumb":[{"text":"Articulos","href":"/artikel/"}]}
			},
			{
				path:'/artikel/*index.amp.html',
				component:ArticlePag,
				meta:{"amp":true}
			},
			{
				path:'/artikel/*',
				component:ArticlePag
			},
			{
				path:'/galerien/*.amp.html',
				component:GalleryPag,
				meta:{"amp":true}
			},
			{
				path:'/galerien/*.html',
				component:GalleryPag
			},
			{
				path:'/shoe-finder/index.amp.html',
				component:Recommender,
				meta:{"amp":true}
			},
			{
				path:'/shoe-finder/',
				component:Recommender
			},
			{
				path:'/tests/:pag(\\d+)?',
				component:ReviewList,
				props:{"breadcrumb":[{"text":"Tests","href":"/tests/"}]}
			},
			{
				path:'/tests/tester/:autor/:pag(\\d+)?',
				component:ReviewList,
				props:{"breadcrumb":[{"text":"Tests","href":"/tests/"}]}
			},
			{
				path:'/authoren/:pag(\\d+)?',
				component:AuthorList
			},
			{
				path:'/marken/',
				component:BrandList
			},
			{
				path:'/shop/:tienda/*',
				component:StorePage
			},
			{
				path:'/courses/@@races/:pathMatch?',
				component:RaceList,
				props:{"breadcrumb":[{"text":"Courses","href":"/courses/@@races/"}]}
			},
			{
				path:'/courses/@@races/*',
				component:RaceList,
				props:{"breadcrumb":[{"text":"Courses","href":"/courses/@@races/"}]}
			},
			{
				path:'/courses/*/inscriptions.html',
				component:RacePage,
				props:{"tipo":"inscriptions","breadcrumb":[{"text":"Courses","href":"/courses/@@races/"}]}
			},
			{
				path:'/courses/*/parcours.html',
				component:RacePage,
				props:{"tipo":"route","breadcrumb":[{"text":"Courses","href":"/courses/@@races/"}]}
			},
			{
				path:'/courses/*/verifier.html',
				component:RacePage,
				props:{"tipo":"verify","breadcrumb":[{"text":"Courses","href":"/courses/@@races/"}]}
			},
			{
				path:'/courses/*/forum.html',
				component:RacePage,
				props:{"tipo":"forum","breadcrumb":[{"text":"Courses","href":"/courses/@@races/"}]}
			},
			{
				path:'/courses/*',
				component:RacePage,
				props:{"tipo":"","breadcrumb":[{"text":"Courses","href":"/courses/@@races/"}]}
			},
			{
				path:'/*/Fragen/*-:id.html',
				component:InquiryPage,
				props:{"prodTypeId":"1","breadcrumb":[{"text":"/Questions","href":"/laufschuhe/fragen/alle/"}]}
			},
			{
				path:'/*/Fragen/*/',
				component:InquiryList,
				props:{"breadcrumb":[{"text":"/Questions","href":"/laufschuhe/fragen/alle/"}],"sections":"@@custom"}
			},
			{
				path:'/kommentare/articulos/*.html',
				component:TalkPage,
				props:{"breadcrumb":[{"text":"Comentarios","href":"/kommentare/articulos/"}]}
			},
			{
				path:'/suche/modelle/alle/*/:q?',
				component:ProductList,
				props:{"prodTypeId":"0","breadcrumb":[{"text":"modelle","href":"/suche/modelle/alle/"}]}
			},
			{
				path:'/suche/modelle/:marca/*/:q?',
				component:ProductList,
				props:{"prodTypeId":"0","breadcrumb":[{"text":"modelle","href":"/suche/modelle/alle/"}]}
			},
			{
				path:'/turnschuhe/*/tag/:pag(\\d+)?',
				component:TagPage,
				props:{"typeId":"1,50,62,64,84"}
			},
			{
				path:'/turnschuhe/vergleiche/',
				component:ComparatorPag,
				props:{"prodTypeId":"1,50,62,64,84"}
			},
			{
				path:'/turnschuhe/vergleich/:prd1/:prd2/*.html',
				component:ComparisonPag,
				props:{"prodTypeId":"1,50,62,64,84"}
			},
			{
				path:'/turnschuhe/fragen/empfehlungen/*',
				component:InquiryList,
				props:{"prodTypeId":"1,50,62,64,84","breadcrumb":[{"text":"Turnschuhe","href":"/turnschuhe/modelle/alle/"}],"Type":1}
			},
			{
				path:'/kommentare/turnschuhe/*.html',
				component:TalkPage,
				props:{"breadcrumb":[{"text":"Comentarios","href":"/kommentare/turnschuhe/"}]}
			},
			{
				path:'/turnschuhe/tests/tester/:pag(\\d+)?',
				component:AuthorList,
				props:{"filter":"reviews","prodTypeId":"1,50,62,64,84"}
			},
			{
				path:'/turnschuhe/tests/tester/:autor/:pag(\\d+)?',
				component:ReviewList,
				props:{"prodTypeId":"1,50,62,64,84","breadcrumb":[{"text":"Reviews","href":"/tests/"}]}
			},
			{
				path:'/turnschuhe/tests/:pag(\\d+)?',
				component:ReviewList,
				props:{"prodTypeId":"1,50,62,64,84","breadcrumb":[{"text":"Reviews","href":"/tests/"}]}
			},
			{
				path:'/turnschuhe/bewertungen/alle/:pag(\\d+)?',
				component:OpinionList,
				props:{"prodTypeId":"1,50,62,64,84","breadcrumb":[{"text":"Opiniones","href":"/bewertungen/alle/"}]}
			},
			{
				path:'/turnschuhe/modelle/alle/*',
				component:ProductList,
				props:{"name":"ProductList","prodTypeId":"1,50,62,64,84","breadcrumb":[{"text":"Turnschuhe","href":"/turnschuhe/modelle/alle/"}]}
			},
			{
				path:'/turnschuhe/modelle/:marca/*',
				component:ProductList,
				props:{"name":"ProductList","prodTypeId":"1,50,62,64,84","breadcrumb":[{"text":"Turnschuhe","href":"/turnschuhe/modelle/alle/"}]}
			},
			{
				path:'/turnschuhe/sale/alle/*',
				component:PriceList,
				props:{"name":"PriceList","prodTypeId":"1,50,62,64,84","breadcrumb":[{"text":"Turnschuhe","href":"/turnschuhe/modelle/alle/"}]}
			},
			{
				path:'/turnschuhe/sale/:tienda/*',
				component:PriceList,
				props:{"name":"PriceList","prodTypeId":"1,50,62,64,84","breadcrumb":[{"text":"Turnschuhe","href":"/turnschuhe/modelle/alle/"}]}
			},
			{
				path:'/turnschuhe/*/@@product_variant/:variant/*',
				component:ProductVariant,
				props:{"name":"ProductPrices","prodTypeId":"1,50,62,64,84"}
			},
			{
				path:'/turnschuhe/*/amp/preis/alle/*',
				component:ProductPrices,
				props:{"prodTypeId":"1,50,62,64,84","breadcrumb":[{"text":"Turnschuhe","href":"/turnschuhe/modelle/alle/"}]},
				meta:{"amp":true}
			},
			{
				path:'/turnschuhe/*/preis/alle/*',
				component:ProductPrices,
				props:{"name":"ProductPrices","prodTypeId":"1,50,62,64,84","breadcrumb":[{"text":"Turnschuhe","href":"/turnschuhe/modelle/alle/"}]}
			},
			{
				path:'/turnschuhe/*/amp/preis/:tienda/*',
				component:ProductPrices,
				props:{"prodTypeId":"1,50,62,64,84","breadcrumb":[{"text":"Turnschuhe","href":"/turnschuhe/modelle/alle/"}]},
				meta:{"amp":true}
			},
			{
				path:'/turnschuhe/*/preis/:tienda/*',
				component:ProductPrices,
				props:{"name":"ProductPrices","prodTypeId":"1,50,62,64,84","breadcrumb":[{"text":"Turnschuhe","href":"/turnschuhe/modelle/alle/"}]}
			},
			{
				path:'/turnschuhe/*index.amp.html',
				component:ProductPag,
				props:{"prodTypeId":"1,50,62,64,84"},
				meta:{"amp":true}
			},
			{
				path:'/turnschuhe/*',
				component:ProductPag,
				props:{"prodTypeId":"1,50,62,64,84"}
			},
			{
				path:'/laufschuhe/*/tag/:pag(\\d+)?',
				component:TagPage,
				props:{"typeId":"1"}
			},
			{
				path:'/laufschuhe/vergleiche/',
				component:ComparatorPag,
				props:{"prodTypeId":"1"}
			},
			{
				path:'/laufschuhe/vergleich/:prd1/:prd2/*.html',
				component:ComparisonPag,
				props:{"prodTypeId":"1"}
			},
			{
				path:'/laufschuhe/fragen/empfehlungen/*',
				component:InquiryList,
				props:{"prodTypeId":"1","breadcrumb":[{"text":"Laufschuhe","href":"/laufschuhe/modelle/alle/"}],"Type":1}
			},
			{
				path:'/kommentare/laufschuhe/*.html',
				component:TalkPage,
				props:{"breadcrumb":[{"text":"Comentarios","href":"/kommentare/laufschuhe/"}]}
			},
			{
				path:'/laufschuhe/tests/tester/:pag(\\d+)?',
				component:AuthorList,
				props:{"filter":"reviews","prodTypeId":"1"}
			},
			{
				path:'/laufschuhe/tests/tester/:autor/:pag(\\d+)?',
				component:ReviewList,
				props:{"prodTypeId":"1","breadcrumb":[{"text":"Reviews","href":"/tests/"}]}
			},
			{
				path:'/laufschuhe/tests/:pag(\\d+)?',
				component:ReviewList,
				props:{"prodTypeId":"1","breadcrumb":[{"text":"Reviews","href":"/tests/"}]}
			},
			{
				path:'/laufschuhe/bewertungen/alle/:pag(\\d+)?',
				component:OpinionList,
				props:{"prodTypeId":"1","breadcrumb":[{"text":"Opiniones","href":"/bewertungen/alle/"}]}
			},
			{
				path:'/laufschuhe/modelle/alle/*',
				component:ProductList,
				props:{"name":"ProductList","prodTypeId":"1","breadcrumb":[{"text":"Laufschuhe","href":"/laufschuhe/modelle/alle/"}]}
			},
			{
				path:'/laufschuhe/modelle/:marca/*',
				component:ProductList,
				props:{"name":"ProductList","prodTypeId":"1","breadcrumb":[{"text":"Laufschuhe","href":"/laufschuhe/modelle/alle/"}]}
			},
			{
				path:'/laufschuhe/sale/alle/*',
				component:PriceList,
				props:{"name":"PriceList","prodTypeId":"1","breadcrumb":[{"text":"Laufschuhe","href":"/laufschuhe/modelle/alle/"}]}
			},
			{
				path:'/laufschuhe/sale/:tienda/*',
				component:PriceList,
				props:{"name":"PriceList","prodTypeId":"1","breadcrumb":[{"text":"Laufschuhe","href":"/laufschuhe/modelle/alle/"}]}
			},
			{
				path:'/laufschuhe/*/@@product_variant/:variant/*',
				component:ProductVariant,
				props:{"name":"ProductPrices","prodTypeId":"1"}
			},
			{
				path:'/laufschuhe/*/amp/preis/alle/*',
				component:ProductPrices,
				props:{"prodTypeId":"1","breadcrumb":[{"text":"Laufschuhe","href":"/laufschuhe/modelle/alle/"}]},
				meta:{"amp":true}
			},
			{
				path:'/laufschuhe/*/preis/alle/*',
				component:ProductPrices,
				props:{"name":"ProductPrices","prodTypeId":"1","breadcrumb":[{"text":"Laufschuhe","href":"/laufschuhe/modelle/alle/"}]}
			},
			{
				path:'/laufschuhe/*/amp/preis/:tienda/*',
				component:ProductPrices,
				props:{"prodTypeId":"1","breadcrumb":[{"text":"Laufschuhe","href":"/laufschuhe/modelle/alle/"}]},
				meta:{"amp":true}
			},
			{
				path:'/laufschuhe/*/preis/:tienda/*',
				component:ProductPrices,
				props:{"name":"ProductPrices","prodTypeId":"1","breadcrumb":[{"text":"Laufschuhe","href":"/laufschuhe/modelle/alle/"}]}
			},
			{
				path:'/laufschuhe/*index.amp.html',
				component:ProductPag,
				props:{"prodTypeId":"1"},
				meta:{"amp":true}
			},
			{
				path:'/laufschuhe/*',
				component:ProductPag,
				props:{"prodTypeId":"1"}
			},
			{
				path:'/sportuhren/*/tag/:pag(\\d+)?',
				component:TagPage,
				props:{"typeId":"45"}
			},
			{
				path:'/sportuhren/vergleiche/',
				component:ComparatorPag,
				props:{"prodTypeId":"45"}
			},
			{
				path:'/sportuhren/vergleich/:prd1/:prd2/*.html',
				component:ComparisonPag,
				props:{"prodTypeId":"45"}
			},
			{
				path:'/sportuhren/fragen/empfehlungen/*',
				component:InquiryList,
				props:{"prodTypeId":"45","breadcrumb":[{"text":"Pulsuhren und Sportuhren","href":"/sportuhren/modelle/alle/"}],"Type":1}
			},
			{
				path:'/kommentare/sportuhren/*.html',
				component:TalkPage,
				props:{"breadcrumb":[{"text":"Comentarios","href":"/kommentare/sportuhren/"}]}
			},
			{
				path:'/sportuhren/tests/tester/:pag(\\d+)?',
				component:AuthorList,
				props:{"filter":"reviews","prodTypeId":"45"}
			},
			{
				path:'/sportuhren/tests/tester/:autor/:pag(\\d+)?',
				component:ReviewList,
				props:{"prodTypeId":"45","breadcrumb":[{"text":"Reviews","href":"/tests/"}]}
			},
			{
				path:'/sportuhren/tests/:pag(\\d+)?',
				component:ReviewList,
				props:{"prodTypeId":"45","breadcrumb":[{"text":"Reviews","href":"/tests/"}]}
			},
			{
				path:'/sportuhren/bewertungen/alle/:pag(\\d+)?',
				component:OpinionList,
				props:{"prodTypeId":"45","breadcrumb":[{"text":"Opiniones","href":"/bewertungen/alle/"}]}
			},
			{
				path:'/sportuhren/modelle/alle/*',
				component:ProductList,
				props:{"name":"ProductList","prodTypeId":"45","breadcrumb":[{"text":"Pulsuhren und Sportuhren","href":"/sportuhren/modelle/alle/"}]}
			},
			{
				path:'/sportuhren/modelle/:marca/*',
				component:ProductList,
				props:{"name":"ProductList","prodTypeId":"45","breadcrumb":[{"text":"Pulsuhren und Sportuhren","href":"/sportuhren/modelle/alle/"}]}
			},
			{
				path:'/sportuhren/sale/alle/*',
				component:PriceList,
				props:{"name":"PriceList","prodTypeId":"45","breadcrumb":[{"text":"Pulsuhren und Sportuhren","href":"/sportuhren/modelle/alle/"}]}
			},
			{
				path:'/sportuhren/sale/:tienda/*',
				component:PriceList,
				props:{"name":"PriceList","prodTypeId":"45","breadcrumb":[{"text":"Pulsuhren und Sportuhren","href":"/sportuhren/modelle/alle/"}]}
			},
			{
				path:'/sportuhren/*/@@product_variant/:variant/*',
				component:ProductVariant,
				props:{"name":"ProductPrices","prodTypeId":"45"}
			},
			{
				path:'/sportuhren/*/amp/preis/alle/*',
				component:ProductPrices,
				props:{"prodTypeId":"45","breadcrumb":[{"text":"Pulsuhren und Sportuhren","href":"/sportuhren/modelle/alle/"}]},
				meta:{"amp":true}
			},
			{
				path:'/sportuhren/*/preis/alle/*',
				component:ProductPrices,
				props:{"name":"ProductPrices","prodTypeId":"45","breadcrumb":[{"text":"Pulsuhren und Sportuhren","href":"/sportuhren/modelle/alle/"}]}
			},
			{
				path:'/sportuhren/*/amp/preis/:tienda/*',
				component:ProductPrices,
				props:{"prodTypeId":"45","breadcrumb":[{"text":"Pulsuhren und Sportuhren","href":"/sportuhren/modelle/alle/"}]},
				meta:{"amp":true}
			},
			{
				path:'/sportuhren/*/preis/:tienda/*',
				component:ProductPrices,
				props:{"name":"ProductPrices","prodTypeId":"45","breadcrumb":[{"text":"Pulsuhren und Sportuhren","href":"/sportuhren/modelle/alle/"}]}
			},
			{
				path:'/sportuhren/*index.amp.html',
				component:ProductPag,
				props:{"prodTypeId":"45"},
				meta:{"amp":true}
			},
			{
				path:'/sportuhren/*',
				component:ProductPag,
				props:{"prodTypeId":"45"}
			},
			{
				path:'/sneaker/*/tag/:pag(\\d+)?',
				component:TagPage,
				props:{"typeId":"50"}
			},
			{
				path:'/sneaker/vergleiche/',
				component:ComparatorPag,
				props:{"prodTypeId":"50"}
			},
			{
				path:'/sneaker/vergleich/:prd1/:prd2/*.html',
				component:ComparisonPag,
				props:{"prodTypeId":"50"}
			},
			{
				path:'/sneaker/fragen/empfehlungen/*',
				component:InquiryList,
				props:{"prodTypeId":"50","breadcrumb":[{"text":"Sneaker","href":"/sneaker/modelle/alle/"}],"Type":1}
			},
			{
				path:'/kommentare/sneaker/*.html',
				component:TalkPage,
				props:{"breadcrumb":[{"text":"Comentarios","href":"/kommentare/sneaker/"}]}
			},
			{
				path:'/sneaker/tests/tester/:pag(\\d+)?',
				component:AuthorList,
				props:{"filter":"reviews","prodTypeId":"50"}
			},
			{
				path:'/sneaker/tests/tester/:autor/:pag(\\d+)?',
				component:ReviewList,
				props:{"prodTypeId":"50","breadcrumb":[{"text":"Reviews","href":"/tests/"}]}
			},
			{
				path:'/sneaker/tests/:pag(\\d+)?',
				component:ReviewList,
				props:{"prodTypeId":"50","breadcrumb":[{"text":"Reviews","href":"/tests/"}]}
			},
			{
				path:'/sneaker/bewertungen/alle/:pag(\\d+)?',
				component:OpinionList,
				props:{"prodTypeId":"50","breadcrumb":[{"text":"Opiniones","href":"/bewertungen/alle/"}]}
			},
			{
				path:'/sneaker/modelle/alle/*',
				component:ProductList,
				props:{"name":"ProductList","prodTypeId":"50","breadcrumb":[{"text":"Sneaker","href":"/sneaker/modelle/alle/"}]}
			},
			{
				path:'/sneaker/modelle/:marca/*',
				component:ProductList,
				props:{"name":"ProductList","prodTypeId":"50","breadcrumb":[{"text":"Sneaker","href":"/sneaker/modelle/alle/"}]}
			},
			{
				path:'/sneaker/sale/alle/*',
				component:PriceList,
				props:{"name":"PriceList","prodTypeId":"50","breadcrumb":[{"text":"Sneaker","href":"/sneaker/modelle/alle/"}]}
			},
			{
				path:'/sneaker/sale/:tienda/*',
				component:PriceList,
				props:{"name":"PriceList","prodTypeId":"50","breadcrumb":[{"text":"Sneaker","href":"/sneaker/modelle/alle/"}]}
			},
			{
				path:'/sneaker/*/@@product_variant/:variant/*',
				component:ProductVariant,
				props:{"name":"ProductPrices","prodTypeId":"50"}
			},
			{
				path:'/sneaker/*/amp/preis/alle/*',
				component:ProductPrices,
				props:{"prodTypeId":"50","breadcrumb":[{"text":"Sneaker","href":"/sneaker/modelle/alle/"}]},
				meta:{"amp":true}
			},
			{
				path:'/sneaker/*/preis/alle/*',
				component:ProductPrices,
				props:{"name":"ProductPrices","prodTypeId":"50","breadcrumb":[{"text":"Sneaker","href":"/sneaker/modelle/alle/"}]}
			},
			{
				path:'/sneaker/*/amp/preis/:tienda/*',
				component:ProductPrices,
				props:{"prodTypeId":"50","breadcrumb":[{"text":"Sneaker","href":"/sneaker/modelle/alle/"}]},
				meta:{"amp":true}
			},
			{
				path:'/sneaker/*/preis/:tienda/*',
				component:ProductPrices,
				props:{"name":"ProductPrices","prodTypeId":"50","breadcrumb":[{"text":"Sneaker","href":"/sneaker/modelle/alle/"}]}
			},
			{
				path:'/sneaker/*index.amp.html',
				component:ProductPag,
				props:{"prodTypeId":"50"},
				meta:{"amp":true}
			},
			{
				path:'/sneaker/*',
				component:ProductPag,
				props:{"prodTypeId":"50"}
			},
			{
				path:'/fitnessschuhe/*/tag/:pag(\\d+)?',
				component:TagPage,
				props:{"typeId":"62"}
			},
			{
				path:'/fitnessschuhe/vergleiche/',
				component:ComparatorPag,
				props:{"prodTypeId":"62"}
			},
			{
				path:'/fitnessschuhe/vergleich/:prd1/:prd2/*.html',
				component:ComparisonPag,
				props:{"prodTypeId":"62"}
			},
			{
				path:'/fitnessschuhe/fragen/empfehlungen/*',
				component:InquiryList,
				props:{"prodTypeId":"62","breadcrumb":[{"text":"Fitnessschuhe","href":"/fitnessschuhe/modelle/alle/"}],"Type":1}
			},
			{
				path:'/kommentare/fitnessschuhe/*.html',
				component:TalkPage,
				props:{"breadcrumb":[{"text":"Comentarios","href":"/kommentare/fitnessschuhe/"}]}
			},
			{
				path:'/fitnessschuhe/tests/tester/:pag(\\d+)?',
				component:AuthorList,
				props:{"filter":"reviews","prodTypeId":"62"}
			},
			{
				path:'/fitnessschuhe/tests/tester/:autor/:pag(\\d+)?',
				component:ReviewList,
				props:{"prodTypeId":"62","breadcrumb":[{"text":"Reviews","href":"/tests/"}]}
			},
			{
				path:'/fitnessschuhe/tests/:pag(\\d+)?',
				component:ReviewList,
				props:{"prodTypeId":"62","breadcrumb":[{"text":"Reviews","href":"/tests/"}]}
			},
			{
				path:'/fitnessschuhe/bewertungen/alle/:pag(\\d+)?',
				component:OpinionList,
				props:{"prodTypeId":"62","breadcrumb":[{"text":"Opiniones","href":"/bewertungen/alle/"}]}
			},
			{
				path:'/fitnessschuhe/modelle/alle/*',
				component:ProductList,
				props:{"name":"ProductList","prodTypeId":"62","breadcrumb":[{"text":"Fitnessschuhe","href":"/fitnessschuhe/modelle/alle/"}]}
			},
			{
				path:'/fitnessschuhe/modelle/:marca/*',
				component:ProductList,
				props:{"name":"ProductList","prodTypeId":"62","breadcrumb":[{"text":"Fitnessschuhe","href":"/fitnessschuhe/modelle/alle/"}]}
			},
			{
				path:'/fitnessschuhe/sale/alle/*',
				component:PriceList,
				props:{"name":"PriceList","prodTypeId":"62","breadcrumb":[{"text":"Fitnessschuhe","href":"/fitnessschuhe/modelle/alle/"}]}
			},
			{
				path:'/fitnessschuhe/sale/:tienda/*',
				component:PriceList,
				props:{"name":"PriceList","prodTypeId":"62","breadcrumb":[{"text":"Fitnessschuhe","href":"/fitnessschuhe/modelle/alle/"}]}
			},
			{
				path:'/fitnessschuhe/*/@@product_variant/:variant/*',
				component:ProductVariant,
				props:{"name":"ProductPrices","prodTypeId":"62"}
			},
			{
				path:'/fitnessschuhe/*/amp/preis/alle/*',
				component:ProductPrices,
				props:{"prodTypeId":"62","breadcrumb":[{"text":"Fitnessschuhe","href":"/fitnessschuhe/modelle/alle/"}]},
				meta:{"amp":true}
			},
			{
				path:'/fitnessschuhe/*/preis/alle/*',
				component:ProductPrices,
				props:{"name":"ProductPrices","prodTypeId":"62","breadcrumb":[{"text":"Fitnessschuhe","href":"/fitnessschuhe/modelle/alle/"}]}
			},
			{
				path:'/fitnessschuhe/*/amp/preis/:tienda/*',
				component:ProductPrices,
				props:{"prodTypeId":"62","breadcrumb":[{"text":"Fitnessschuhe","href":"/fitnessschuhe/modelle/alle/"}]},
				meta:{"amp":true}
			},
			{
				path:'/fitnessschuhe/*/preis/:tienda/*',
				component:ProductPrices,
				props:{"name":"ProductPrices","prodTypeId":"62","breadcrumb":[{"text":"Fitnessschuhe","href":"/fitnessschuhe/modelle/alle/"}]}
			},
			{
				path:'/fitnessschuhe/*index.amp.html',
				component:ProductPag,
				props:{"prodTypeId":"62"},
				meta:{"amp":true}
			},
			{
				path:'/fitnessschuhe/*',
				component:ProductPag,
				props:{"prodTypeId":"62"}
			},
			{
				path:'/crossfit-schuhe/*/tag/:pag(\\d+)?',
				component:TagPage,
				props:{"typeId":"64"}
			},
			{
				path:'/crossfit-schuhe/vergleiche/',
				component:ComparatorPag,
				props:{"prodTypeId":"64"}
			},
			{
				path:'/crossfit-schuhe/vergleich/:prd1/:prd2/*.html',
				component:ComparisonPag,
				props:{"prodTypeId":"64"}
			},
			{
				path:'/crossfit-schuhe/fragen/empfehlungen/*',
				component:InquiryList,
				props:{"prodTypeId":"64","breadcrumb":[{"text":"Crossfit-Schuhe","href":"/crossfit-schuhe/modelle/alle/"}],"Type":1}
			},
			{
				path:'/kommentare/crossfit-schuhe/*.html',
				component:TalkPage,
				props:{"breadcrumb":[{"text":"Comentarios","href":"/kommentare/crossfit-schuhe/"}]}
			},
			{
				path:'/crossfit-schuhe/tests/tester/:pag(\\d+)?',
				component:AuthorList,
				props:{"filter":"reviews","prodTypeId":"64"}
			},
			{
				path:'/crossfit-schuhe/tests/tester/:autor/:pag(\\d+)?',
				component:ReviewList,
				props:{"prodTypeId":"64","breadcrumb":[{"text":"Reviews","href":"/tests/"}]}
			},
			{
				path:'/crossfit-schuhe/tests/:pag(\\d+)?',
				component:ReviewList,
				props:{"prodTypeId":"64","breadcrumb":[{"text":"Reviews","href":"/tests/"}]}
			},
			{
				path:'/crossfit-schuhe/bewertungen/alle/:pag(\\d+)?',
				component:OpinionList,
				props:{"prodTypeId":"64","breadcrumb":[{"text":"Opiniones","href":"/bewertungen/alle/"}]}
			},
			{
				path:'/crossfit-schuhe/modelle/alle/*',
				component:ProductList,
				props:{"name":"ProductList","prodTypeId":"64","breadcrumb":[{"text":"Crossfit-Schuhe","href":"/crossfit-schuhe/modelle/alle/"}]}
			},
			{
				path:'/crossfit-schuhe/modelle/:marca/*',
				component:ProductList,
				props:{"name":"ProductList","prodTypeId":"64","breadcrumb":[{"text":"Crossfit-Schuhe","href":"/crossfit-schuhe/modelle/alle/"}]}
			},
			{
				path:'/crossfit-schuhe/sale/alle/*',
				component:PriceList,
				props:{"name":"PriceList","prodTypeId":"64","breadcrumb":[{"text":"Crossfit-Schuhe","href":"/crossfit-schuhe/modelle/alle/"}]}
			},
			{
				path:'/crossfit-schuhe/sale/:tienda/*',
				component:PriceList,
				props:{"name":"PriceList","prodTypeId":"64","breadcrumb":[{"text":"Crossfit-Schuhe","href":"/crossfit-schuhe/modelle/alle/"}]}
			},
			{
				path:'/crossfit-schuhe/*/@@product_variant/:variant/*',
				component:ProductVariant,
				props:{"name":"ProductPrices","prodTypeId":"64"}
			},
			{
				path:'/crossfit-schuhe/*/amp/preis/alle/*',
				component:ProductPrices,
				props:{"prodTypeId":"64","breadcrumb":[{"text":"Crossfit-Schuhe","href":"/crossfit-schuhe/modelle/alle/"}]},
				meta:{"amp":true}
			},
			{
				path:'/crossfit-schuhe/*/preis/alle/*',
				component:ProductPrices,
				props:{"name":"ProductPrices","prodTypeId":"64","breadcrumb":[{"text":"Crossfit-Schuhe","href":"/crossfit-schuhe/modelle/alle/"}]}
			},
			{
				path:'/crossfit-schuhe/*/amp/preis/:tienda/*',
				component:ProductPrices,
				props:{"prodTypeId":"64","breadcrumb":[{"text":"Crossfit-Schuhe","href":"/crossfit-schuhe/modelle/alle/"}]},
				meta:{"amp":true}
			},
			{
				path:'/crossfit-schuhe/*/preis/:tienda/*',
				component:ProductPrices,
				props:{"name":"ProductPrices","prodTypeId":"64","breadcrumb":[{"text":"Crossfit-Schuhe","href":"/crossfit-schuhe/modelle/alle/"}]}
			},
			{
				path:'/crossfit-schuhe/*index.amp.html',
				component:ProductPag,
				props:{"prodTypeId":"64"},
				meta:{"amp":true}
			},
			{
				path:'/crossfit-schuhe/*',
				component:ProductPag,
				props:{"prodTypeId":"64"}
			},
			{
				path:'/@@home/*/tag/:pag(\\d+)?',
				component:TagPage,
				props:{"typeId":"@@id"}
			},
			{
				path:'/@@home/@@comparator/',
				component:ComparatorPag,
				props:{"prodTypeId":"@@id"}
			},
			{
				path:'/@@home/@@comparison/:prd1/:prd2/*.html',
				component:ComparisonPag,
				props:{"prodTypeId":"@@id"}
			},
			{
				path:'/@@home/@@inquiries/@@recomendations/*',
				component:InquiryList,
				props:{"prodTypeId":"@@id","breadcrumb":[{"text":"@@name","href":"/@@home/@@products/@@products_all/"}],"Type":1}
			},
			{
				path:'/@@comments/@@home/*.html',
				component:TalkPage,
				props:{"breadcrumb":[{"text":"Comentarios","href":"/@@comments/@@home/"}]}
			},
			{
				path:'/@@home/@@reviews/@@testers/:pag(\\d+)?',
				component:AuthorList,
				props:{"filter":"reviews","prodTypeId":"@@id"}
			},
			{
				path:'/@@home/@@reviews/@@testers/:autor/:pag(\\d+)?',
				component:ReviewList,
				props:{"prodTypeId":"@@id","breadcrumb":[{"text":"Reviews","href":"/@@reviews/"}]}
			},
			{
				path:'/@@home/@@reviews/:pag(\\d+)?',
				component:ReviewList,
				props:{"prodTypeId":"@@id","breadcrumb":[{"text":"Reviews","href":"/@@reviews/"}]}
			},
			{
				path:'/@@home/@@opinions/@@opinions_all/:pag(\\d+)?',
				component:OpinionList,
				props:{"prodTypeId":"@@id","breadcrumb":[{"text":"Opiniones","href":"/@@opinions/@@opinions_all/"}]}
			},
			{
				path:'/@@home/@@products/@@products_all/*',
				component:ProductList,
				props:{"name":"ProductList","prodTypeId":"@@id","breadcrumb":[{"text":"@@name","href":"/@@home/@@products/@@products_all/"}]}
			},
			{
				path:'/@@home/@@products/:marca/*',
				component:ProductList,
				props:{"name":"ProductList","prodTypeId":"@@id","breadcrumb":[{"text":"@@name","href":"/@@home/@@products/@@products_all/"}]}
			},
			{
				path:'/@@home/@@prices/@@prices_all/*',
				component:PriceList,
				props:{"name":"PriceList","prodTypeId":"@@id","breadcrumb":[{"text":"@@name","href":"/@@home/@@products/@@products_all/"}]}
			},
			{
				path:'/@@home/@@prices/:tienda/*',
				component:PriceList,
				props:{"name":"PriceList","prodTypeId":"@@id","breadcrumb":[{"text":"@@name","href":"/@@home/@@products/@@products_all/"}]}
			},
			{
				path:'/@@home/*/@@product_variant/:variant/*',
				component:ProductVariant,
				props:{"name":"ProductPrices","prodTypeId":"@@id"}
			},
			{
				path:'/@@home/*/amp/@@product_prices/@@prices_all/*',
				component:ProductPrices,
				props:{"prodTypeId":"@@id","breadcrumb":[{"text":"@@name","href":"/@@home/@@products/@@products_all/"}]},
				meta:{"amp":true}
			},
			{
				path:'/@@home/*/@@product_prices/@@prices_all/*',
				component:ProductPrices,
				props:{"name":"ProductPrices","prodTypeId":"@@id","breadcrumb":[{"text":"@@name","href":"/@@home/@@products/@@products_all/"}]}
			},
			{
				path:'/@@home/*/amp/@@product_prices/:tienda/*',
				component:ProductPrices,
				props:{"prodTypeId":"@@id","breadcrumb":[{"text":"@@name","href":"/@@home/@@products/@@products_all/"}]},
				meta:{"amp":true}
			},
			{
				path:'/@@home/*/@@product_prices/:tienda/*',
				component:ProductPrices,
				props:{"name":"ProductPrices","prodTypeId":"@@id","breadcrumb":[{"text":"@@name","href":"/@@home/@@products/@@products_all/"}]}
			},
			{
				path:'/@@home/*index.amp.html',
				component:ProductPag,
				props:{"prodTypeId":"@@id"},
				meta:{"amp":true}
			},
			{
				path:'/@@home/*',
				component:ProductPag,
				props:{"prodTypeId":"@@id"}
			},
			{
				path:'/fitness-tracker/*/tag/:pag(\\d+)?',
				component:TagPage,
				props:{"typeId":"73"}
			},
			{
				path:'/fitness-tracker/vergleiche/',
				component:ComparatorPag,
				props:{"prodTypeId":"73"}
			},
			{
				path:'/fitness-tracker/vergleich/:prd1/:prd2/*.html',
				component:ComparisonPag,
				props:{"prodTypeId":"73"}
			},
			{
				path:'/fitness-tracker/fragen/empfehlungen/*',
				component:InquiryList,
				props:{"prodTypeId":"73","breadcrumb":[{"text":"Fitness-tracker","href":"/fitness-tracker/modelle/alle/"}],"Type":1}
			},
			{
				path:'/kommentare/fitness-tracker/*.html',
				component:TalkPage,
				props:{"breadcrumb":[{"text":"Comentarios","href":"/kommentare/fitness-tracker/"}]}
			},
			{
				path:'/fitness-tracker/tests/tester/:pag(\\d+)?',
				component:AuthorList,
				props:{"filter":"reviews","prodTypeId":"73"}
			},
			{
				path:'/fitness-tracker/tests/tester/:autor/:pag(\\d+)?',
				component:ReviewList,
				props:{"prodTypeId":"73","breadcrumb":[{"text":"Reviews","href":"/tests/"}]}
			},
			{
				path:'/fitness-tracker/tests/:pag(\\d+)?',
				component:ReviewList,
				props:{"prodTypeId":"73","breadcrumb":[{"text":"Reviews","href":"/tests/"}]}
			},
			{
				path:'/fitness-tracker/bewertungen/alle/:pag(\\d+)?',
				component:OpinionList,
				props:{"prodTypeId":"73","breadcrumb":[{"text":"Opiniones","href":"/bewertungen/alle/"}]}
			},
			{
				path:'/fitness-tracker/modelle/alle/*',
				component:ProductList,
				props:{"name":"ProductList","prodTypeId":"73","breadcrumb":[{"text":"Fitness-tracker","href":"/fitness-tracker/modelle/alle/"}]}
			},
			{
				path:'/fitness-tracker/modelle/:marca/*',
				component:ProductList,
				props:{"name":"ProductList","prodTypeId":"73","breadcrumb":[{"text":"Fitness-tracker","href":"/fitness-tracker/modelle/alle/"}]}
			},
			{
				path:'/fitness-tracker/sale/alle/*',
				component:PriceList,
				props:{"name":"PriceList","prodTypeId":"73","breadcrumb":[{"text":"Fitness-tracker","href":"/fitness-tracker/modelle/alle/"}]}
			},
			{
				path:'/fitness-tracker/sale/:tienda/*',
				component:PriceList,
				props:{"name":"PriceList","prodTypeId":"73","breadcrumb":[{"text":"Fitness-tracker","href":"/fitness-tracker/modelle/alle/"}]}
			},
			{
				path:'/fitness-tracker/*/@@product_variant/:variant/*',
				component:ProductVariant,
				props:{"name":"ProductPrices","prodTypeId":"73"}
			},
			{
				path:'/fitness-tracker/*/amp/preis/alle/*',
				component:ProductPrices,
				props:{"prodTypeId":"73","breadcrumb":[{"text":"Fitness-tracker","href":"/fitness-tracker/modelle/alle/"}]},
				meta:{"amp":true}
			},
			{
				path:'/fitness-tracker/*/preis/alle/*',
				component:ProductPrices,
				props:{"name":"ProductPrices","prodTypeId":"73","breadcrumb":[{"text":"Fitness-tracker","href":"/fitness-tracker/modelle/alle/"}]}
			},
			{
				path:'/fitness-tracker/*/amp/preis/:tienda/*',
				component:ProductPrices,
				props:{"prodTypeId":"73","breadcrumb":[{"text":"Fitness-tracker","href":"/fitness-tracker/modelle/alle/"}]},
				meta:{"amp":true}
			},
			{
				path:'/fitness-tracker/*/preis/:tienda/*',
				component:ProductPrices,
				props:{"name":"ProductPrices","prodTypeId":"73","breadcrumb":[{"text":"Fitness-tracker","href":"/fitness-tracker/modelle/alle/"}]}
			},
			{
				path:'/fitness-tracker/*index.amp.html',
				component:ProductPag,
				props:{"prodTypeId":"73"},
				meta:{"amp":true}
			},
			{
				path:'/fitness-tracker/*',
				component:ProductPag,
				props:{"prodTypeId":"73"}
			},
			{
				path:'/smartwatch/*/tag/:pag(\\d+)?',
				component:TagPage,
				props:{"typeId":"74"}
			},
			{
				path:'/smartwatch/vergleiche/',
				component:ComparatorPag,
				props:{"prodTypeId":"74"}
			},
			{
				path:'/smartwatch/vergleich/:prd1/:prd2/*.html',
				component:ComparisonPag,
				props:{"prodTypeId":"74"}
			},
			{
				path:'/smartwatch/fragen/empfehlungen/*',
				component:InquiryList,
				props:{"prodTypeId":"74","breadcrumb":[{"text":"Smartwatch","href":"/smartwatch/modelle/alle/"}],"Type":1}
			},
			{
				path:'/kommentare/smartwatch/*.html',
				component:TalkPage,
				props:{"breadcrumb":[{"text":"Comentarios","href":"/kommentare/smartwatch/"}]}
			},
			{
				path:'/smartwatch/tests/tester/:pag(\\d+)?',
				component:AuthorList,
				props:{"filter":"reviews","prodTypeId":"74"}
			},
			{
				path:'/smartwatch/tests/tester/:autor/:pag(\\d+)?',
				component:ReviewList,
				props:{"prodTypeId":"74","breadcrumb":[{"text":"Reviews","href":"/tests/"}]}
			},
			{
				path:'/smartwatch/tests/:pag(\\d+)?',
				component:ReviewList,
				props:{"prodTypeId":"74","breadcrumb":[{"text":"Reviews","href":"/tests/"}]}
			},
			{
				path:'/smartwatch/bewertungen/alle/:pag(\\d+)?',
				component:OpinionList,
				props:{"prodTypeId":"74","breadcrumb":[{"text":"Opiniones","href":"/bewertungen/alle/"}]}
			},
			{
				path:'/smartwatch/modelle/alle/*',
				component:ProductList,
				props:{"name":"ProductList","prodTypeId":"74","breadcrumb":[{"text":"Smartwatch","href":"/smartwatch/modelle/alle/"}]}
			},
			{
				path:'/smartwatch/modelle/:marca/*',
				component:ProductList,
				props:{"name":"ProductList","prodTypeId":"74","breadcrumb":[{"text":"Smartwatch","href":"/smartwatch/modelle/alle/"}]}
			},
			{
				path:'/smartwatch/sale/alle/*',
				component:PriceList,
				props:{"name":"PriceList","prodTypeId":"74","breadcrumb":[{"text":"Smartwatch","href":"/smartwatch/modelle/alle/"}]}
			},
			{
				path:'/smartwatch/sale/:tienda/*',
				component:PriceList,
				props:{"name":"PriceList","prodTypeId":"74","breadcrumb":[{"text":"Smartwatch","href":"/smartwatch/modelle/alle/"}]}
			},
			{
				path:'/smartwatch/*/@@product_variant/:variant/*',
				component:ProductVariant,
				props:{"name":"ProductPrices","prodTypeId":"74"}
			},
			{
				path:'/smartwatch/*/amp/preis/alle/*',
				component:ProductPrices,
				props:{"prodTypeId":"74","breadcrumb":[{"text":"Smartwatch","href":"/smartwatch/modelle/alle/"}]},
				meta:{"amp":true}
			},
			{
				path:'/smartwatch/*/preis/alle/*',
				component:ProductPrices,
				props:{"name":"ProductPrices","prodTypeId":"74","breadcrumb":[{"text":"Smartwatch","href":"/smartwatch/modelle/alle/"}]}
			},
			{
				path:'/smartwatch/*/amp/preis/:tienda/*',
				component:ProductPrices,
				props:{"prodTypeId":"74","breadcrumb":[{"text":"Smartwatch","href":"/smartwatch/modelle/alle/"}]},
				meta:{"amp":true}
			},
			{
				path:'/smartwatch/*/preis/:tienda/*',
				component:ProductPrices,
				props:{"name":"ProductPrices","prodTypeId":"74","breadcrumb":[{"text":"Smartwatch","href":"/smartwatch/modelle/alle/"}]}
			},
			{
				path:'/smartwatch/*index.amp.html',
				component:ProductPag,
				props:{"prodTypeId":"74"},
				meta:{"amp":true}
			},
			{
				path:'/smartwatch/*',
				component:ProductPag,
				props:{"prodTypeId":"74"}
			},
			{
				path:'/fahrradcomputer/*/tag/:pag(\\d+)?',
				component:TagPage,
				props:{"typeId":"75"}
			},
			{
				path:'/fahrradcomputer/vergleiche/',
				component:ComparatorPag,
				props:{"prodTypeId":"75"}
			},
			{
				path:'/fahrradcomputer/vergleich/:prd1/:prd2/*.html',
				component:ComparisonPag,
				props:{"prodTypeId":"75"}
			},
			{
				path:'/fahrradcomputer/fragen/empfehlungen/*',
				component:InquiryList,
				props:{"prodTypeId":"75","breadcrumb":[{"text":"Fahrradcomputer","href":"/fahrradcomputer/modelle/alle/"}],"Type":1}
			},
			{
				path:'/kommentare/fahrradcomputer/*.html',
				component:TalkPage,
				props:{"breadcrumb":[{"text":"Comentarios","href":"/kommentare/fahrradcomputer/"}]}
			},
			{
				path:'/fahrradcomputer/tests/tester/:pag(\\d+)?',
				component:AuthorList,
				props:{"filter":"reviews","prodTypeId":"75"}
			},
			{
				path:'/fahrradcomputer/tests/tester/:autor/:pag(\\d+)?',
				component:ReviewList,
				props:{"prodTypeId":"75","breadcrumb":[{"text":"Reviews","href":"/tests/"}]}
			},
			{
				path:'/fahrradcomputer/tests/:pag(\\d+)?',
				component:ReviewList,
				props:{"prodTypeId":"75","breadcrumb":[{"text":"Reviews","href":"/tests/"}]}
			},
			{
				path:'/fahrradcomputer/bewertungen/alle/:pag(\\d+)?',
				component:OpinionList,
				props:{"prodTypeId":"75","breadcrumb":[{"text":"Opiniones","href":"/bewertungen/alle/"}]}
			},
			{
				path:'/fahrradcomputer/modelle/alle/*',
				component:ProductList,
				props:{"name":"ProductList","prodTypeId":"75","breadcrumb":[{"text":"Fahrradcomputer","href":"/fahrradcomputer/modelle/alle/"}]}
			},
			{
				path:'/fahrradcomputer/modelle/:marca/*',
				component:ProductList,
				props:{"name":"ProductList","prodTypeId":"75","breadcrumb":[{"text":"Fahrradcomputer","href":"/fahrradcomputer/modelle/alle/"}]}
			},
			{
				path:'/fahrradcomputer/sale/alle/*',
				component:PriceList,
				props:{"name":"PriceList","prodTypeId":"75","breadcrumb":[{"text":"Fahrradcomputer","href":"/fahrradcomputer/modelle/alle/"}]}
			},
			{
				path:'/fahrradcomputer/sale/:tienda/*',
				component:PriceList,
				props:{"name":"PriceList","prodTypeId":"75","breadcrumb":[{"text":"Fahrradcomputer","href":"/fahrradcomputer/modelle/alle/"}]}
			},
			{
				path:'/fahrradcomputer/*/@@product_variant/:variant/*',
				component:ProductVariant,
				props:{"name":"ProductPrices","prodTypeId":"75"}
			},
			{
				path:'/fahrradcomputer/*/amp/preis/alle/*',
				component:ProductPrices,
				props:{"prodTypeId":"75","breadcrumb":[{"text":"Fahrradcomputer","href":"/fahrradcomputer/modelle/alle/"}]},
				meta:{"amp":true}
			},
			{
				path:'/fahrradcomputer/*/preis/alle/*',
				component:ProductPrices,
				props:{"name":"ProductPrices","prodTypeId":"75","breadcrumb":[{"text":"Fahrradcomputer","href":"/fahrradcomputer/modelle/alle/"}]}
			},
			{
				path:'/fahrradcomputer/*/amp/preis/:tienda/*',
				component:ProductPrices,
				props:{"prodTypeId":"75","breadcrumb":[{"text":"Fahrradcomputer","href":"/fahrradcomputer/modelle/alle/"}]},
				meta:{"amp":true}
			},
			{
				path:'/fahrradcomputer/*/preis/:tienda/*',
				component:ProductPrices,
				props:{"name":"ProductPrices","prodTypeId":"75","breadcrumb":[{"text":"Fahrradcomputer","href":"/fahrradcomputer/modelle/alle/"}]}
			},
			{
				path:'/fahrradcomputer/*index.amp.html',
				component:ProductPag,
				props:{"prodTypeId":"75"},
				meta:{"amp":true}
			},
			{
				path:'/fahrradcomputer/*',
				component:ProductPag,
				props:{"prodTypeId":"75"}
			},
			{
				path:'/trekkingschuhe/*/tag/:pag(\\d+)?',
				component:TagPage,
				props:{"typeId":"84"}
			},
			{
				path:'/trekkingschuhe/vergleiche/',
				component:ComparatorPag,
				props:{"prodTypeId":"84"}
			},
			{
				path:'/trekkingschuhe/vergleich/:prd1/:prd2/*.html',
				component:ComparisonPag,
				props:{"prodTypeId":"84"}
			},
			{
				path:'/trekkingschuhe/fragen/empfehlungen/*',
				component:InquiryList,
				props:{"prodTypeId":"84","breadcrumb":[{"text":"Trekkingschuhe","href":"/trekkingschuhe/modelle/alle/"}],"Type":1}
			},
			{
				path:'/kommentare/trekkingschuhe/*.html',
				component:TalkPage,
				props:{"breadcrumb":[{"text":"Comentarios","href":"/kommentare/trekkingschuhe/"}]}
			},
			{
				path:'/trekkingschuhe/tests/tester/:pag(\\d+)?',
				component:AuthorList,
				props:{"filter":"reviews","prodTypeId":"84"}
			},
			{
				path:'/trekkingschuhe/tests/tester/:autor/:pag(\\d+)?',
				component:ReviewList,
				props:{"prodTypeId":"84","breadcrumb":[{"text":"Reviews","href":"/tests/"}]}
			},
			{
				path:'/trekkingschuhe/tests/:pag(\\d+)?',
				component:ReviewList,
				props:{"prodTypeId":"84","breadcrumb":[{"text":"Reviews","href":"/tests/"}]}
			},
			{
				path:'/trekkingschuhe/bewertungen/alle/:pag(\\d+)?',
				component:OpinionList,
				props:{"prodTypeId":"84","breadcrumb":[{"text":"Opiniones","href":"/bewertungen/alle/"}]}
			},
			{
				path:'/trekkingschuhe/modelle/alle/*',
				component:ProductList,
				props:{"name":"ProductList","prodTypeId":"84","breadcrumb":[{"text":"Trekkingschuhe","href":"/trekkingschuhe/modelle/alle/"}]}
			},
			{
				path:'/trekkingschuhe/modelle/:marca/*',
				component:ProductList,
				props:{"name":"ProductList","prodTypeId":"84","breadcrumb":[{"text":"Trekkingschuhe","href":"/trekkingschuhe/modelle/alle/"}]}
			},
			{
				path:'/trekkingschuhe/sale/alle/*',
				component:PriceList,
				props:{"name":"PriceList","prodTypeId":"84","breadcrumb":[{"text":"Trekkingschuhe","href":"/trekkingschuhe/modelle/alle/"}]}
			},
			{
				path:'/trekkingschuhe/sale/:tienda/*',
				component:PriceList,
				props:{"name":"PriceList","prodTypeId":"84","breadcrumb":[{"text":"Trekkingschuhe","href":"/trekkingschuhe/modelle/alle/"}]}
			},
			{
				path:'/trekkingschuhe/*/@@product_variant/:variant/*',
				component:ProductVariant,
				props:{"name":"ProductPrices","prodTypeId":"84"}
			},
			{
				path:'/trekkingschuhe/*/amp/preis/alle/*',
				component:ProductPrices,
				props:{"prodTypeId":"84","breadcrumb":[{"text":"Trekkingschuhe","href":"/trekkingschuhe/modelle/alle/"}]},
				meta:{"amp":true}
			},
			{
				path:'/trekkingschuhe/*/preis/alle/*',
				component:ProductPrices,
				props:{"name":"ProductPrices","prodTypeId":"84","breadcrumb":[{"text":"Trekkingschuhe","href":"/trekkingschuhe/modelle/alle/"}]}
			},
			{
				path:'/trekkingschuhe/*/amp/preis/:tienda/*',
				component:ProductPrices,
				props:{"prodTypeId":"84","breadcrumb":[{"text":"Trekkingschuhe","href":"/trekkingschuhe/modelle/alle/"}]},
				meta:{"amp":true}
			},
			{
				path:'/trekkingschuhe/*/preis/:tienda/*',
				component:ProductPrices,
				props:{"name":"ProductPrices","prodTypeId":"84","breadcrumb":[{"text":"Trekkingschuhe","href":"/trekkingschuhe/modelle/alle/"}]}
			},
			{
				path:'/trekkingschuhe/*index.amp.html',
				component:ProductPag,
				props:{"prodTypeId":"84"},
				meta:{"amp":true}
			},
			{
				path:'/trekkingschuhe/*',
				component:ProductPag,
				props:{"prodTypeId":"84"}
			},
			{
				path:'/wanderschuhe/*/tag/:pag(\\d+)?',
				component:TagPage,
				props:{"typeId":"1041"}
			},
			{
				path:'/wanderschuhe/vergleiche/',
				component:ComparatorPag,
				props:{"prodTypeId":"1041"}
			},
			{
				path:'/wanderschuhe/vergleich/:prd1/:prd2/*.html',
				component:ComparisonPag,
				props:{"prodTypeId":"1041"}
			},
			{
				path:'/wanderschuhe/fragen/empfehlungen/*',
				component:InquiryList,
				props:{"prodTypeId":"1041","breadcrumb":[{"text":"Wanderschuhe","href":"/wanderschuhe/modelle/alle/"}],"Type":1}
			},
			{
				path:'/kommentare/wanderschuhe/*.html',
				component:TalkPage,
				props:{"breadcrumb":[{"text":"Comentarios","href":"/kommentare/wanderschuhe/"}]}
			},
			{
				path:'/wanderschuhe/tests/tester/:pag(\\d+)?',
				component:AuthorList,
				props:{"filter":"reviews","prodTypeId":"1041"}
			},
			{
				path:'/wanderschuhe/tests/tester/:autor/:pag(\\d+)?',
				component:ReviewList,
				props:{"prodTypeId":"1041","breadcrumb":[{"text":"Reviews","href":"/tests/"}]}
			},
			{
				path:'/wanderschuhe/tests/:pag(\\d+)?',
				component:ReviewList,
				props:{"prodTypeId":"1041","breadcrumb":[{"text":"Reviews","href":"/tests/"}]}
			},
			{
				path:'/wanderschuhe/bewertungen/alle/:pag(\\d+)?',
				component:OpinionList,
				props:{"prodTypeId":"1041","breadcrumb":[{"text":"Opiniones","href":"/bewertungen/alle/"}]}
			},
			{
				path:'/wanderschuhe/modelle/alle/*',
				component:ProductList,
				props:{"name":"ProductList","prodTypeId":"1041","breadcrumb":[{"text":"Wanderschuhe","href":"/wanderschuhe/modelle/alle/"}]}
			},
			{
				path:'/wanderschuhe/modelle/:marca/*',
				component:ProductList,
				props:{"name":"ProductList","prodTypeId":"1041","breadcrumb":[{"text":"Wanderschuhe","href":"/wanderschuhe/modelle/alle/"}]}
			},
			{
				path:'/wanderschuhe/sale/alle/*',
				component:PriceList,
				props:{"name":"PriceList","prodTypeId":"1041","breadcrumb":[{"text":"Wanderschuhe","href":"/wanderschuhe/modelle/alle/"}]}
			},
			{
				path:'/wanderschuhe/sale/:tienda/*',
				component:PriceList,
				props:{"name":"PriceList","prodTypeId":"1041","breadcrumb":[{"text":"Wanderschuhe","href":"/wanderschuhe/modelle/alle/"}]}
			},
			{
				path:'/wanderschuhe/*/@@product_variant/:variant/*',
				component:ProductVariant,
				props:{"name":"ProductPrices","prodTypeId":"1041"}
			},
			{
				path:'/wanderschuhe/*/amp/preis/alle/*',
				component:ProductPrices,
				props:{"prodTypeId":"1041","breadcrumb":[{"text":"Wanderschuhe","href":"/wanderschuhe/modelle/alle/"}]},
				meta:{"amp":true}
			},
			{
				path:'/wanderschuhe/*/preis/alle/*',
				component:ProductPrices,
				props:{"name":"ProductPrices","prodTypeId":"1041","breadcrumb":[{"text":"Wanderschuhe","href":"/wanderschuhe/modelle/alle/"}]}
			},
			{
				path:'/wanderschuhe/*/amp/preis/:tienda/*',
				component:ProductPrices,
				props:{"prodTypeId":"1041","breadcrumb":[{"text":"Wanderschuhe","href":"/wanderschuhe/modelle/alle/"}]},
				meta:{"amp":true}
			},
			{
				path:'/wanderschuhe/*/preis/:tienda/*',
				component:ProductPrices,
				props:{"name":"ProductPrices","prodTypeId":"1041","breadcrumb":[{"text":"Wanderschuhe","href":"/wanderschuhe/modelle/alle/"}]}
			},
			{
				path:'/wanderschuhe/*index.amp.html',
				component:ProductPag,
				props:{"prodTypeId":"1041"},
				meta:{"amp":true}
			},
			{
				path:'/wanderschuhe/*',
				component:ProductPag,
				props:{"prodTypeId":"1041"}
			},
			{
				path:'/sport-bhs/sale/alle/*',
				component:PriceList,
				props:{"name":"PriceList","prodTypeId":"58","breadcrumb":[{"text":"Sport-BHs","href":"/sport-bhs/sale/alle/"}]}
			},
			{
				path:'/sport-bhs/sale/:tienda/*',
				component:PriceList,
				props:{"name":"PriceList","prodTypeId":"58","breadcrumb":[{"text":"Sport-BHs","href":"/sport-bhs/sale/alle/"}]}
			},
			{
				path:'/laufhose/sale/alle/*',
				component:PriceList,
				props:{"name":"PriceList","prodTypeId":"71","breadcrumb":[{"text":"Laufhose","href":"/laufhose/sale/alle/"}]}
			},
			{
				path:'/laufhose/sale/:tienda/*',
				component:PriceList,
				props:{"name":"PriceList","prodTypeId":"71","breadcrumb":[{"text":"Laufhose","href":"/laufhose/sale/alle/"}]}
			},
			{
				path:'/fitnessgerate/alle/*',
				component:PriceList,
				props:{"name":"PriceList","prodTypeId":"105","breadcrumb":[{"text":"Fitnessgeräte","href":"/fitnessgerate/alle/"}]}
			},
			{
				path:'/fitnessgerate/:tienda/*',
				component:PriceList,
				props:{"name":"PriceList","prodTypeId":"105","breadcrumb":[{"text":"Fitnessgeräte","href":"/fitnessgerate/alle/"}]}
			},
			{
				path:'/running-gutscheincodes/',
				component:DiscountCodesList
			},
			{
				path:'/zeitrechner/pace-rechner/',
				component:Calculator
			},
			{
				path:'/zeitrechner/pace-rechner/marathon/',
				component:CalculatorMarathon
			},
			{
				path:'/zeitrechner/pace-rechner/halbmarathon/',
				component:CalculatorHalfMarathon
			},
			{
				path:'/zeitrechner/pace-rechner/10-km/',
				component:Calculator10Km
			},
			{
				path:'/seite-nicht-gefunde',
				component:NotFoundPage
			},
			{
				path:'/404.html',
				component:NotFoundPage
			},
			{
				path:'/enlace/',
				component:RedirectingPag
			},
			{
				path:'/*/enlace/',
				component:RedirectingPag
			},
			
      {
        path: '/running-music/list',
        component: AudiosList
      },
      {
        path: '/running-music/playlist/:id',
        component: AudiosPage
      },
      {
        path: '/auth/login',
        component: AuthLogin
      },
      {
        path: '/auth/redirect',
        component: AuthRedirect
      },
      {
        path: '/auth/callback',
        component: AuthCallback
      },
      {
        path: '/auth/data',
        component: AuthData
      },
      {
        path: '/auth/newsletter',
        component: AuthNewsletter
      },
      {
        path: '/auth/remember',
        component: AuthRemember
      },
      {
        path: '/auth/password/:token',
        component: AuthPassword
      },
      {
        path: '/auth/signin',
        component: AuthSignin
      },
      {
        path: '/countries/:country',
        component: CountriesRedirect
      },
      {
        path: '/*',
        component: LandingPag
      }
    ]
  })
}
